import { LanguageProps } from '@crema/models/Apps';
import defaultConfig from '@crema/constants/defaultConfig';

const languageData: LanguageProps[] = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'en',
  },
  {
    languageId: 'romanian',
    locale: 'ro',
    name: 'Romana',
    icon: 'ro',
  },
];
export default languageData;

export function getDataForLocale(locale: string) {
  const data = languageData.find((item) => item.locale === locale);

  if (!data) {
    return defaultConfig.locale;
  }

  return data;
}

import axios from '@crema/services/axios';
import type { AxiosResponse } from 'axios';
import { apiUrl } from '@crema/constants/AppConst';
import { destroyCookie, setCookie } from 'nookies';
import { getBrowserLocale } from '@crema/helpers';

const jwtAxios = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': getBrowserLocale(),
  },
});
jwtAxios.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);
export const setAuthToken = (token?: string) => {
  if (token) {
    // eslint-disable-next-line no-debugger
    // debugger;
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;

    setCookie(null, 'auth-token', token, {
      path: '/',
      sameSite: 'strict',
      maxAge: 60 * 60, // expires in 3 days
    });
    // jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;// Change this according your requirement
    // localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    // delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    destroyCookie(null, 'auth-token', {
      path: '/',
      sameSite: 'strict',
      maxAge: 0,
    });
  }
};

export const setAuthUserId = (userId?: string | number) => {
  if (userId) {
    setCookie(null, 'auth-id', userId.toString(), {
      path: '/',
      sameSite: 'strict',
      maxAge: 60 * 60, // expires in 3 days
    });
  } else {
    destroyCookie(null, 'auth-id', {
      path: '/',
      sameSite: 'strict',
      maxAge: 0,
    });
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');

  destroyCookie(null, 'auth-token', {
    path: '/',
    sameSite: 'strict',
    maxAge: 0,
  });

  setAuthToken();
  setAuthUserId();
};

export default jwtAxios;

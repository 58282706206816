import { roRO } from '@mui/material/locale';
import roMessages from '../locales/ro_RO.json';

const RoLang = {
  messages: {
    ...roMessages,
  },
  muiLocale: roRO,
  locale: 'ro-RO',
};
export default RoLang;

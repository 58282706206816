import enLang from './entries/en-US';
import roLang from './entries/ro-RO';

const AppLocale: any = {
  en: enLang,
  ro: roLang,
};

export default AppLocale;

export const _trans = ({ id, values }: { id: string; values?: any }) => id;

// ForJWT Auth
import { getUserFromJwtAuth } from '@crema/helpers/AuthHelper';
import {
  JWTAuthActionsProps,
  useJWTAuth,
  useJWTAuthActions,
} from '@crema/services/auth/JWTAuthProvider';
import { useRouter } from 'next/router';
import { matchPath } from 'react-router-dom';

export interface AuthActions {
  signInUser: JWTAuthActionsProps['signInUser'];
  logout: JWTAuthActionsProps['logout'];
  signUpUser: JWTAuthActionsProps['signUpUser'];
}

export const useAuthUser = () => {
  // console.log('useAuthUser');
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  // console.log('user:', user, 'isAuthenticated:', isAuthenticated, 'isLoading:', isLoading)

  const router = useRouter();
  const guestRoutes = [
    '/learning-hub',
    '/learning-hub/[slug]',
    '/blog',
    '/blog/blog-details/[id]',
    '/app-contact-us',
    '/faq',
    '/signin',
    '/signup',
    '/email-verify/failed/[reason]',
    '/',
  ];

  const mixedRoutes = [
    '/email-verify/failed/force',
    '/email-verify/failed/expired',
    '/email-verify/failed/already-verified',
    '/email-verify/failed/hash',
  ];

  const foundRoutes = guestRoutes.find((route) => {
    if (matchPath(router.route, route)) {
      return true;
    }

    return false;
  });

  const foundMixedRoute = mixedRoutes.find((route) => {
    if (matchPath(router.route, route)) {
      return true;
    }

    // match with query, but ignore additional query params
    if (matchPath(router.asPath.replace(/\?.*/, ''), route)) {
      return true;
    }

    return false;
  });

  const authIsRequired = !foundRoutes;
  // console.log('%s authIsRequired? ', router.route, authIsRequired);

  const mixedRoute = !!foundMixedRoute;
  // console.log('%s mixedRoute? ', router.route, mixedRoute);

  const isForceValidation = user?.force_validation === true;

  return {
    isLoading,
    isAuthenticated,
    isForceValidation,
    authIsRequired,
    mixedRoute,
    user: getUserFromJwtAuth(user),
    isAdmin: user?.role?.includes('admin') || false,
  };
};

export const useAuthMethod = (): AuthActions => {
  // console.log('useAuthMethod');
  const { signInUser, signUpUser, logout } = useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
  };
};
